import React from "react";
import "./_subTitle.scss";
const index = (props) => {
  return (
    <div
      className={`subTitle_container  ${props.className} ? props.className : '' `}
    >
      {" "}
      {props.subTitle}{" "}
    </div>
  );
};

export default index;
