import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressbarComponent from "./ProgressbarComponent.jsx";
import "./_progbar.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const RoundProgBar = (props) => {
  const {
    leaveTypeStatus,
    leaveTypes,
    color,
    className,
    width,
    value,
    pathColor,
  } = props;
  const leaveBalance = value !== undefined ? value[value.length - 1] : null;

  return (
    <>
      {leaveTypeStatus === "loading" ? (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ProgressbarComponent
          label={leaveTypes}
          color={color}
          width={width}
          className={className}
        >
          <CircularProgressbar
            value={leaveBalance}
            text={`${leaveBalance ? leaveBalance : 0} Days`}
            styles={buildStyles({
              pathColor: pathColor,
              textColor: "black",
              strokeLinecap: "butt",
            })}
          />
        </ProgressbarComponent>
      )}
    </>
  );
};

export default RoundProgBar;
