import React from "react";
import "./_progbar.scss";

const ProgressbarComponent = (props) => {
  return (
    <div className={` ${props.className}`}>
      <div style={props.width}>
        {props.children}
        <p>{props.description}</p>
      </div>
      <span style={{ color: props.color }}>{props.label}</span>
    </div>
  );
};
export default ProgressbarComponent;
